import photoBooth from './Images/Gallery/360photo.jpg'
import bar from './Images/Gallery/bar.jpg'
import letters from './Images/Gallery/letters.jpg'

const galImages = [
    {
        link: photoBooth,
        name: "Welcome to Sunlight Party Rentals!",
        left: 4,
    },
    // {
    //     link: letters,
    //     name: "Marquee letter"
    // },
    // {
    //     link: bar,
    //     name: "Tables & Chairs"
    // }
];

export default galImages;